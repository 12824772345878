import Api from "@/config/Api";

export default {
  getInvoiceToQuoteRelation(options = {}) {
    return Api().get("/me/stats/invoice-to-quote", options);
  },

  getSalesProducts(options = {}) {
    return Api().get("/me/stats/products", options);
  },

  getDownloadReportExcel(options = {}) {
    return Api().get("/me/stats/products/download/excel", options);
  },

  getDataInvoices(options = {}) {
    return Api().get("/me/stats/invoices", options);
  },

  getDownloadReportInvoicesExcel(options = {}) {
    return Api().get("/me/stats/invoices/download/excel", options);
  },

  getSendReportInvoicesEmail(options = {}) {
    return Api().get("/me/stats/invoices/download/pdf", options);
  }
};
