<template>
  <WrapperCardComponent
    v-loading="loading"
    title="Facturas vs Cotizaciones"
    description="Comparativa de facturas realizadas vs cotizaciones pendientes"
  >
    <template v-slot:content>
      <div>
        <template v-if="isSearchYear">
          <b-row class="my-2">
            <b-col xs="12" sm="12" md="12">
              <el-select
                placeholder="Filtrar por año"
                v-model="year"
                @change="onChangeYear"
                filterable
                clearable
              >
                <el-option
                  v-for="year in years"
                  :key="year"
                  :label="year"
                  :value="year"
                >
                </el-option>
              </el-select>
            </b-col>
          </b-row>
        </template>
        <LineQuotesToInvoicesComponent
          class="mb-4"
          :setYear="setYear"
          @onLoad="onLoadLines"
          @onBeforeLoad="onBeforeLoadLines"
        />
        <p class="float-right">
          <LinkComponent
            title="Ver mis ventas"
            classNames="text-color-secondary"
            @onClick="handleLinkClick"
          />
        </p>
      </div>
    </template>
  </WrapperCardComponent>
</template>

<script>
import LineQuotesToInvoicesComponent from "./LineQuotesToInvoices";
import WrapperCardComponent from "../../components/WrapperCard";
import LinkComponent from "../../components/Link";
import { years } from "@/services/DateServices";

export default {
  name: "QuotesToInvoicesComponent",
  data() {
    return {
      loading: true,
      year: null,
      setYear: null,
      years: years()
    };
  },
  methods: {
    handleLinkClick() {
      this.$router.push({ name: "sales.documents" });
    },
    onLoadLines() {
      this.loading = false;
    },
    onBeforeLoadLines() {
      this.loading = true;
    },
    onChangeYear() {
      this.setYear = this.year;
    }
  },
  components: {
    LineQuotesToInvoicesComponent,
    WrapperCardComponent,
    LinkComponent
  },
  props: {
    isSearchYear: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 300px;
  float: right;
}
</style>
