<script>
import { currentYear } from "@/services/DateServices";
import { Line } from "vue-chartjs";
import reportService from "../services/reportService";

export default {
  extends: Line,
  name: "LineQuotesToInvoicesComponent",
  data() {
    return {
      labels: ["", "", "", ""],
      invoices: [],
      quotes: [],
      year: this.setYear
    };
  },
  watch: {
    setYear(v) {
      this.year = v;
      this.getInvoiceToQuoteRelation();
    }
  },
  methods: {
    async getInvoiceToQuoteRelation() {
      this.handleResetChart();
      this.$emit("onBeforeLoad");

      const response = await reportService
        .getInvoiceToQuoteRelation({
          params: {
            year: this.year || currentYear
          }
        })
        .finally(() => this.$emit("onLoad"));

      const { success, data: report } = response.data;
      const { labels, invoices, quotes } = report;

      this.labels = labels;
      this.invoices = invoices;
      this.quotes = quotes;

      if (success) {
        this.handleRenderChart();
      }
    },
    handleResetChart() {
      this.invoices = [];
      this.quotes = [];
    },
    handleRenderChart() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              label: "Facturas",
              borderColor: "#31355C",
              fill: false,
              spanGaps: true,
              data: this.invoices
            },
            {
              label: "Cotizaciones",
              borderColor: "#0FAEA8",
              fill: false,
              spanGaps: true,
              data: this.quotes
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  precision: 0
                }
              }
            ]
          }
        }
      );
    }
  },
  mounted() {
    this.getInvoiceToQuoteRelation();
  },
  props: {
    setYear: {
      default: null
    }
  }
};
</script>

<style></style>
