<template>
  <el-card class="box-card md__border p-2">
    <div slot="header" class="header clearfix">
      <div class="d-flex justify-content-between align-items-center">
        <div class="descriptions">
          <h4 class="h4 font-bold">{{ title }}</h4>
          <p class="m-0">{{ description }}</p>
        </div>
        <div class="actions">
          <slot name="actions"></slot>

          <el-dropdown @command="dropdownMenuSelected" v-if="isMoreOptions">
            <img
              :src="icoOptionGray"
              alt="options"
              width="24"
              class="float-right"
            />
            <slot name="dropdown-menu"></slot>
          </el-dropdown>
        </div>
      </div>
    </div>

    <slot name="content"></slot>
  </el-card>
</template>

<script>
import { icoOptionGray } from "@/services/resources";

export default {
  name: "WrapperCardComponent",
  data: () => ({
    icoOptionGray
  }),
  methods: {
    dropdownMenuSelected(menuItem) {
      this.$emit("dropdownMenuSelected", {
        menuItem
      });
    }
  },
  props: {
    title: {
      default: null
    },
    description: {
      default: null
    },
    isMoreOptions: {
      type: Boolean,
      default: false
    }
  }
};
</script>
